export const getUserInformations = user => {
  const managerCheckbox = user.children[4].children[0];
  const isActiveCheckbox = user.children[5].children[0];
  return {
    manager: managerCheckbox.checked,
    is_active: isActiveCheckbox.checked,
  };
};

export const sendInformations = async (content, token) => {
  const formData = new FormData();
  formData.append('content', JSON.stringify(content));
  const params = {
    method: 'POST',
    headers: { 'X-Requested-With': 'XMLHttpRequest', 'X-CSRFToken': token },
    body: formData,
  };

  try {
    const response = await fetch(window.reverse('users-update'), params);
    if (!response.ok) {
      if (response.status !== 400) {
        throw new Error(`Http error occured, ${response.status} ${response.statusText}`);
      }
      const errors = response.statusText;
      return { error: errors };
    }
    const data = response.statusText;

    return { success: data };
  } catch (error) {
    console.error(error);
    throw new Error(gettext('An error occured, admin have been notify'));
  }
};

export const SaveUpdate = async () => {
  const content = {};
  const users = Array.from(document.getElementsByClassName('line-user'));
  users.forEach(user => { content[user.id] = getUserInformations(user); });
  const token = document.querySelector("input[name='csrfmiddlewaretoken']").value;
  await sendInformations(content, token);
};

export const CompanyUserTabLoaded = async () => {
  document.querySelectorAll('.check-element').forEach(box => { box.addEventListener('click', () => SaveUpdate()); });
};

export default {
  CompanyUserTabLoaded,
};

export const fr = {
  emptyTable: 'Aucune donnée disponible dans le tableau',
  lengthMenu: 'Afficher _MENU_ éléments',
  loadingRecords: 'Chargement...',
  processing: 'Traitement...',
  zeroRecords: 'Aucun élément correspondant trouvé',
  paginate: {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>',
  },
  aria: {
    sortAscending: ': activer pour trier la colonne par ordre croissant',
    sortDescending: ': activer pour trier la colonne par ordre décroissant',
  },
  select: {
    rows: {
      _: '%d lignes sélectionnées',
      0: 'Aucune ligne sélectionnée',
      1: '1 ligne sélectionnée',
    },
    1: '1 ligne selectionnée',
    _: '%d lignes selectionnées',
    cells: {
      1: '1 cellule sélectionnée',
      _: '%d cellules sélectionnées',
    },
    columns: {
      1: '1 colonne sélectionnée',
      _: '%d colonnes sélectionnées',
    },
  },
  autoFill: {
    cancel: 'Annuler',
    fill: 'Remplir toutes les cellules avec <i>%d</i>',
    fillHorizontal: 'Remplir les cellules horizontalement',
    fillVertical: 'Remplir les cellules verticalement',
    info: 'Exemple de remplissage automatique',
  },
  searchBuilder: {
    conditions: {
      date: {
        after: 'Après le',
        before: 'Avant le',
        between: 'Entre',
        empty: 'Vide',
        equals: 'Egal à',
        not: 'Différent de',
        notBetween: 'Pas entre',
        notEmpty: 'Non vide',
      },
      number: {
        between: 'Entre',
        empty: 'Vide',
        equals: 'Egal à',
        gt: 'Supérieur à',
        gte: 'Supérieur ou égal à',
        lt: 'Inférieur à',
        lte: 'Inférieur ou égal à',
        not: 'Différent de',
        notBetween: 'Pas entre',
        notEmpty: 'Non vide',
      },
      string: {
        contains: 'Contient',
        empty: 'Vide',
        endsWith: 'Se termine par',
        equals: 'Egal à',
        not: 'Différent de',
        notEmpty: 'Non vide',
        startsWith: 'Commence par',
      },
      array: {
        equals: 'Egal à',
        empty: 'Vide',
        contains: 'Contient',
        not: 'Différent de',
        notEmpty: 'Non vide',
        without: 'Sans',
      },
    },
    add: 'Ajouter une condition',
    button: {
      0: 'Recherche avancée',
      _: 'Recherche avancée (%d)',
    },
    clearAll: 'Effacer tout',
    condition: 'Condition',
    data: 'Donnée',
    deleteTitle: 'Supprimer la règle de filtrage',
    logicAnd: 'Et',
    logicOr: 'Ou',
    title: {
      0: 'Recherche avancée',
      _: 'Recherche avancée (%d)',
    },
    value: 'Valeur',
  },
  searchPanes: {
    clearMessage: 'Effacer tout',
    count: '{total}',
    title: 'Filtres actifs - %d',
    collapse: {
      0: 'Volet de recherche',
      _: 'Volet de recherche (%d)',
    },
    countFiltered: '{shown} ({total})',
    emptyPanes: 'Pas de volet de recherche',
    loadMessage: 'Chargement du volet de recherche...',
  },
  buttons: {
    copyKeys: 'Appuyer sur ctrl ou u2318 + C pour copier les données du tableau dans votre presse-papier.',
    collection: 'Collection',
    colvis: 'Visibilité colonnes',
    colvisRestore: 'Rétablir visibilité',
    copy: 'Copier',
    copySuccess: {
      1: '1 ligne copiée dans le presse-papier',
      _: '%ds lignes copiées dans le presse-papier',
    },
    copyTitle: 'Copier dans le presse-papier',
    csv: 'CSV',
    excel: 'Excel',
    pageLength: {
      '-1': 'Afficher toutes les lignes',
      1: 'Afficher 1 ligne',
      _: 'Afficher %d lignes',
    },
    pdf: 'PDF',
    print: 'Imprimer',
  },
  decimal: ',',
  info: 'Affichage de _START_ à _END_ sur _TOTAL_ éléments',
  infoEmpty: 'Affichage de 0 à 0 sur 0 éléments',
  infoThousands: '.',
  search: 'Rechercher:',
  searchPlaceholder: '...',
  thousands: '.',
  infoFiltered: '(filtrés depuis un total de _MAX_ éléments)',
  datetime: {
    previous: 'Précédent',
    next: 'Suivant',
    hours: 'Heures',
    minutes: 'Minutes',
    seconds: 'Secondes',
    unknown: '-',
    amPm: [
      'am',
      'pm',
    ],
  },
  editor: {
    close: 'Fermer',
    create: {
      button: 'Nouveaux',
      title: 'Créer une nouvelle entrée',
      submit: 'Envoyer',
    },
    edit: {
      button: 'Editer',
      title: 'Editer Entrée',
      submit: 'Modifier',
    },
    remove: {
      button: 'Supprimer',
      title: 'Supprimer',
      submit: 'Supprimer',
    },
    error: {
      system: "Une erreur système s'est produite",
    },
    multi: {
      title: 'Valeurs Multiples',
      restore: 'Rétablir Modification',
    },
  },
};

export const it = {
  infoFiltered: '(filtrati da _MAX_ elementi totali)',
  infoThousands: '.',
  loadingRecords: 'Caricamento...',
  processing: 'Elaborazione...',
  search: 'Cerca:',
  paginate: {
    first: '<<',
    previous: '<',
    next: '>',
    last: '>>',
  },
  aria: {
    sortAscending: ': attiva per ordinare la colonna in ordine crescente',
    sortDescending: ': attiva per ordinare la colonna in ordine decrescente',
  },
  autoFill: {
    cancel: 'Annulla',
    fill: 'Riempi tutte le celle con <i>%d</i>',
    fillHorizontal: 'Riempi celle orizzontalmente',
    fillVertical: 'Riempi celle verticalmente',
    info: 'Informazioni',
  },
  buttons: {
    collection: 'Collezione <span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-s"></span>',
    colvis: 'Visibilità Colonna',
    colvisRestore: 'Ripristina visibilità',
    copy: 'Copia',
    copyKeys: 'Premi ctrl o u2318 + C per copiare i dati della tabella nella tua clipboard di sistema.<br /><br />Per annullare, clicca questo messaggio o premi ESC.',
    copySuccess: {
      1: 'Copiata 1 riga nella clipboard',
      _: 'Copiate %d righe nella clipboard',
    },
    copyTitle: 'Copia nella Clipboard',
    csv: 'CSV',
    excel: 'Excel',
    pageLength: {
      '-1': 'Mostra tutte le righe',
      1: 'Mostra 1 riga',
      _: 'Mostra %d righe',
    },
    pdf: 'PDF',
    print: 'Stampa',
  },
  decimal: ',',
  emptyTable: 'Nessun dato disponibile nella tabella',
  info: 'Risultati da _START_ a _END_ di _TOTAL_ elementi',
  infoEmpty: 'Risultati da 0 a 0 di 0 elementi',
  lengthMenu: 'Mostra _MENU_ elementi',
  searchBuilder: {
    add: 'Aggiungi Condizione',
    button: {
      0: 'Generatore di Ricerca',
      _: 'Generatori di Ricerca (%d)',
    },
    clearAll: 'Pulisci Tutto',
    condition: 'Condizione',
    conditions: {
      date: {
        after: 'Dopo',
        before: 'Prima',
        between: 'Tra',
        empty: 'Vuoto',
        equals: 'Uguale A',
        not: 'Non',
        notBetween: 'Non Tra',
        notEmpty: 'Non Vuoto',
      },
      number: {
        between: 'Tra',
        empty: 'Vuoto',
        equals: 'Uguale A',
        gt: 'Maggiore Di',
        gte: 'Maggiore O Uguale A',
        lt: 'Minore Di',
        lte: 'Minore O Uguale A',
        not: 'Non',
        notBetween: 'Non Tra',
        notEmpty: 'Non Vuoto',
      },
      string: {
        contains: 'Contiene',
        empty: 'Vuoto',
        endsWith: 'Finisce Con',
        equals: 'Uguale A',
        not: 'Non',
        notEmpty: 'Non Vuoto',
        startsWith: 'Inizia Con',
      },
      array: {
        equals: 'Uguale A',
        empty: 'Vuoto',
        contains: 'Contiene',
        not: 'Non',
        notEmpty: 'Non Vuoto',
        without: 'Senza',
      },
    },
    data: 'Dati',
    deleteTitle: 'Elimina regola filtro',
    leftTitle: 'Criterio di Riduzione Rientro',
    logicAnd: 'E',
    logicOr: 'O',
    rightTitle: 'Criterio di Aumento Rientro',
    title: {
      0: 'Generatore di Ricerca',
      _: 'Generatori di Ricerca (%d)',
    },
    value: 'Valore',
  },
  searchPanes: {
    clearMessage: 'Pulisci Tutto',
    collapse: {
      0: 'Pannello di Ricerca',
      _: 'Pannelli di Ricerca (%d)',
    },
    count: '{total}',
    countFiltered: '{shown} ({total})',
    emptyPanes: 'Nessun Pannello di Ricerca',
    loadMessage: 'Caricamento Pannello di Ricerca',
    title: 'Filtri Attivi - %d',
  },
  select: {
    1: '%d riga selezionata',
    _: '%d righe selezionate',
    cells: {
      1: '1 cella selezionata',
      _: '%d celle selezionate',
    },
    columns: {
      1: '1 colonna selezionata',
      _: '%d colonne selezionate',
    },
    rows: {
      1: '1 riga selezionata',
      _: '%d righe selezionate',
      0: '%d righe selezionate',
    },
    0: 'Nessuna riga selezionata',
    2: '%d righe selezionate',
  },
  zeroRecords: 'Nessun elemento corrispondente trovato',
  datetime: {
    amPm: [
      'am',
      'pm',
    ],
    hours: 'ore',
    minutes: 'minuti',
    next: 'successivo',
    previous: 'precedente',
    seconds: 'secondi',
    unknown: 'sconosciuto',
    weekdays: [
      'Dom',
      'Lun',
      'Mar',
      'Mer',
      'Gio',
      'Ven',
      'Sab',
    ],
    months: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ],
  },
  editor: {
    close: 'Chiudi',
    create: {
      button: 'Nuovo',
      submit: 'Aggiungi',
      title: 'Aggiungi nuovo elemento',
    },
    edit: {
      button: 'Modifica',
      submit: 'Modifica',
      title: 'Modifica elemento',
    },
    error: {
      system: 'Errore del sistema.',
    },
    multi: {
      info: 'Gli elementi selezionati contengono valori diversi. Per modificare e impostare tutti gli elementi per questa selezione allo stesso valore, premi o clicca qui, altrimenti ogni cella manterrà il suo valore attuale.',
      noMulti: 'Questa selezione può essere modificata individualmente, ma non se fa parte di un gruppo.',
      restore: 'Annulla le modifiche',
      title: 'Valori multipli',
    },
    remove: {
      button: 'Rimuovi',
      confirm: {
        _: 'Sei sicuro di voler cancellare %d righe?',
        1: 'Sei sicuro di voler cancellare 1 riga?',
      },
      submit: 'Rimuovi',
      title: 'Rimuovi',
    },
  },
  searchPlaceholder: 'Valore di esempio',
  thousands: '.',
};

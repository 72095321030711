import { fr, it } from '../locales/datatable';

const pageLanguage = document.documentElement.lang;
let language;

if (pageLanguage === 'it') {
  language = it;
} else {
  language = fr;
}
function format (d) {
  return `${d.html}`;
}

const detailRows = [];

function refreshAdsByLocation (position) {
  document.getElementById('id_lat').value = position.coords.latitude;
  document.getElementById('id_lng').value = position.coords.longitude;
}

export const instantiatePublicSearchForm = () => {
  const btnSelector = document.getElementById('btn-flux-search');
  const oldButtonContent = btnSelector.innerHTML;
  navigator.geolocation.getCurrentPosition(refreshAdsByLocation);

  // init search autocomplete field
  $('#id_search').autoComplete({
    resolverSettings: {
      url: window.reverse('ad-search-autocomplete'),
    },
    noResultsText: '', // disable 'no result' entry
  }).on('autocomplete.select', (event, item) => {
    // if we select an autocomplete proposal, preselect matching subclassification
    const option = new Option(item.classification, item.id, true, true);
    // clear preselection if already selected
    $('#id_subclassifications').val(null).trigger('change');
    // add option
    $('#id_subclassifications').append(option).trigger('change');
  });
  // init result datatable
  const table = $('#table-results').DataTable({
    language,
    searching: false,
    lengthChange: false,
    serverSide: true,
    processing: true,
    ajax: {
      url: '/api/business/v1/public-ad-search/',
    },
    columns: [
      { data: 'id', visible: false },
      {
        class:          'details-control',
        orderable:      false,
        data:           null,
        defaultContent: '',
        render () {
          return '<i class="fa fa-angle-down text-success" />';
        },
      },
      { data: 'description', visible: false },
      { data: 'type',
        searchable: false,
        sortable: false,
        render (data, index, row) {
          if (data === 'OUT') {
            return `<span class="badge badge-primary text-white">${row.type_display}</span>`;
          }
          return `<span class="badge badge-danger text-white">${row.type_display}</span>`;
        } },
      { data: 'picture',
        visible: false },
      { data: 'classification',
        searchable: false,
        sortable: false,
        render (data, index, row) {
          let finalString = '';
          if (row.tags.length !== 0) {
            row.tags.forEach(obj => {
              finalString += `<span class="badge badge-secondary text-white">${obj}</span> `;
            });
            return finalString + row.classification;
          }
          return row.classification;
        } },
      { data: 'stock_date', searchable: false, sortable: false },
      { data: 'distance',
        searchable: false,
        sortable: false,
        render (data) {
          return `${Math.round(data)} Km`;
        } },
      { data: 'html', visible: false },
      { data: 'type_display', visible: false },
      { data: 'tags', visible: false },
    ],
  }).on('draw', () => {
    btnSelector.innerHTML = oldButtonContent;
    btnSelector.disabled = false;
  });

  $('#table-results tbody').on('click', 'tr td.details-control', function () {
    const tr = $(this).closest('tr');
    const row = table.row(tr);
    const idx = $.inArray(tr.attr('id'), detailRows);

    if (row.child.isShown()) {
      $(this).children().addClass('text-success');
      $(this).children().addClass('fa-angle-down');
      $(this).children().removeClass('text-danger');
      $(this).children().removeClass('fa-angle-up');
      row.child.hide();

      // Remove from the 'open' array
      detailRows.splice(idx, 1);
    } else {
      $(this).children().removeClass('text-success');
      $(this).children().removeClass('fa-angle-down');
      $(this).children().addClass('text-danger');
      $(this).children().addClass('fa-angle-up');
      row.child(format(row.data())).show();

      // Add to the 'open' array
      if (idx === -1) {
        detailRows.push(tr.attr('id'));
      }
    }
  });
  // On each draw, loop over the `detailRows` array and show any child rows
  table.on('draw', () => {
    $.each(detailRows, (i, id) => {
      $(`#${id} td.details-control`).trigger('click');
    });
  });

  btnSelector.addEventListener('click', () => {
    // disable button and show spinner
    btnSelector.disabled = true;
    btnSelector.innerHTML = `<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> ${gettext('Searching ...')}`;
    table.serverSide = true;
    const searchUrl = window.reverse('public-ad-search-list');

    // Get the form
    const form = document.querySelector('#modal-form');

    // Get all field data from the form
    const data = new FormData(form);

    // Convert to a query string
    const queryString = new URLSearchParams(data).toString();

    table.ajax.url(`${searchUrl}?${queryString}`).load(() => {
      document.getElementById('div-results').classList.remove('d-none');
    });
  });

  const rangeSelector = document.getElementById('id_distance');
  const textSelector = document.getElementById('text_distance');

  rangeSelector.addEventListener('input', () => {
    textSelector.innerHTML = `${rangeSelector.value} Km`;
  });
};

export default {
  instantiatePublicSearchForm,
};

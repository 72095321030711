import { fr, it } from '../locales/datatable';

const pageLanguage = document.documentElement.lang;
let language;

if (pageLanguage === 'it') {
  language = it;
} else {
  language = fr;
}

function format (d) {
  return `
      <div class="card">
            <div style="display: flex; flex: 1 1 auto;">
                <div class="img-thumbnail">
                    <img class="" src="${d.picture}" alt="Card image cap" />
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <b>${gettext('Description')} :</b> ${d.description ? d.description : '-'}<br/>
                        </div>
                        <div class="col-6">
                          <b>${gettext('In synergy')} :</b> <i class="fa fa-${d.in_synergy ? 'check-circle text-success' : 'times-circle text-danger'}" /><br/>
                        </div>
                    </div>
                </div>
            </div>
      </div>
  `;
}

const detailRows = [];

export const instantiateAdSearchForm = () => {
  function toggleDistanceCriteria () {
    const elements = $('#id_see_all').parent().parent().siblings();
    elements.prop('disabled', (i, v) => !v);
    elements.toggleClass('d-none');
  }

  const btnSelector = document.getElementById('btn-flux-search');
  const oldButtonContent = btnSelector.innerHTML;

  // init search autocomplete field
  $('#id_search').autoComplete({
    resolverSettings: {
      url: window.reverse('ad-search-autocomplete'),
    },
    noResultsText: '', // disable 'no result' entry
  }).on('autocomplete.select', (event, item) => {
    // if we select an autocomplete proposal, preselect matching subclassification
    const option = new Option(item.classification, item.id, true, true);
    // clear preselection if already selected
    $('#id_subclassifications').val(null).trigger('change');
    // add option
    $('#id_subclassifications').append(option).trigger('change');
    if ($('#id_search').val() === ' ') {
      // prevent case where value is not empty
      $('#id_search').val('');
    }
  });

  // Get all field data from the form
  const table = $('#table-results').DataTable({
    language,
    searching: false,
    lengthChange: false,
    serverSide: true,
    processing: true,
    ajax: {
      url: '/api/frontend/ad-search/',
    },
    columns: [
      { data: 'id', visible: false },
      {
        class:          'details-control',
        orderable:      false,
        data:           null,
        defaultContent: '',
        render () {
          return '<i class="fa fa-angle-down text-success" />';
        },
      },
      { data: 'description', visible: false },
      { data: 'picture', visible: false },
      { data: 'in_synergy', visible: false },
      { data: 'type', searchable: false, sortable: false },
      { data: 'classification',
        searchable: false,
        sortable: false,
        render (data, index, row) {
          let finalString = '';
          if (row.tags.length !== 0) {
            row.tags.forEach(obj => {
              finalString += `<span class="badge badge-secondary text-white">${obj}</span> `;
            });
            return finalString + row.classification;
          }
          return row.classification;
        } },
      { data: 'company_name', searchable: false, sortable: false },
      { data: 'stock_date', searchable: false, sortable: false },
      { data: 'quantity', searchable: false, sortable: false },
      { data: 'distance',
        searchable: false,
        sortable: false,
        render (data) {
          if (data !== null) {
            return `${Math.round(data)} Km`;
          }
          return '-';
        } },
      { data: 'tags', visible: false },
    ],
    dom: 'frtipB',
    buttons: [
      {
        text: '<i class="fa fa-file-excel"></i>',
        titleAttr: gettext('Result export'),
        action () {
          const form = document.querySelector('#modal-form');
          const data = new FormData(form);
          const queryString = new URLSearchParams(data).toString();

          window.location.href = `/api/frontend/ad-search/export_to_xls/?${queryString}`;
        },
        className: 'text-white bg-success',
      },
    ],
  }).on('draw', () => {
    btnSelector.innerHTML = oldButtonContent;
    btnSelector.disabled = false;
  });

  $('#table-results tbody').on('click', 'tr td.details-control', function () {
    const tr = $(this).closest('tr');
    const row = table.row(tr);
    const idx = $.inArray(tr.attr('id'), detailRows);

    if (row.child.isShown()) {
      $(this).children().addClass('text-success');
      $(this).children().addClass('fa-angle-down');
      $(this).children().removeClass('text-danger');
      $(this).children().removeClass('fa-angle-up');
      row.child.hide();

      // Remove from the 'open' array
      detailRows.splice(idx, 1);
    } else {
      $(this).children().removeClass('text-success');
      $(this).children().removeClass('fa-angle-down');
      $(this).children().addClass('text-danger');
      $(this).children().addClass('fa-angle-up');
      row.child(format(row.data())).show();

      // Add to the 'open' array
      if (idx === -1) {
        detailRows.push(tr.attr('id'));
      }
    }
  });
  // On each draw, loop over the `detailRows` array and show any child rows
  table.on('draw', () => {
    $.each(detailRows, (i, id) => {
      $(`#${id} td.details-control`).trigger('click');
    });
  });
  btnSelector.addEventListener('click', () => {
    // disable button and show spinner
    btnSelector.disabled = true;
    btnSelector.innerHTML = `<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> ${gettext('Searching ...')}`;
    // we cant use datatable.ajax.data because mutliple values are send as key[]=value1&key[]=value2 instead of key=value1&key=value2
    // so we override

    const form = document.querySelector('#modal-form');
    const data = new FormData(form);
    const queryString = new URLSearchParams(data).toString();

    table.ajax.url(`/api/frontend/ad-search/?${queryString}`).load();
  });

  const rangeSelector = document.getElementById('id_distance');
  const textSelector = document.getElementById('text_distance');

  rangeSelector.addEventListener('input', () => {
    textSelector.innerHTML = `${rangeSelector.value} Km`;
  });

  // on company select, empty city
  $('#id_company').on('select2:select', () => {
    $('#id_city').val(null).trigger('change');
  });

  // on city select, empty company
  $('#id_city').on('select2:select', () => {
    $('#id_company').val(null).trigger('change');
  });

  // on see all select, disable other location criteria
  $('#id_see_all').on('change', () => {
    toggleDistanceCriteria();
  });

  // simulate click on "see all" to hide location criteria by default
  $('#id_see_all').click();

  // Pre-fill the search area and launch the search when the page is given some query params
  const urlSearchParams = new URLSearchParams(window.location.hash);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params?.classification) {
    // By default the form search ad with type=OUT. If the given param is OUT we search "demand" fluxes
    if (params.type === 'OUT') {
      // We change the display of the buttons
      const offer = document.getElementById('id_type_1');
      const demand = document.getElementById('id_type_0');
      offer.removeAttribute('checked');
      demand.setAttribute('checked', '');
      offer.parentNode.setAttribute('class', 'btn btn-outline-primary');
      demand.parentNode.setAttribute('class', 'btn btn-outline-danger active');
    }

    // Pre-fill classification
    const classificationOption = new Option(params.classification, params.classificationId, true, true);
    // clear preselection if already selected
    $('#id_subclassifications').val(null).trigger('change');
    // add option
    $('#id_subclassifications').append(classificationOption).trigger('change');

    // Pre-fill tags
    // clear preselection if already selected
    $('#id_ad_tags').val(null).trigger('change');
    // Convert string to array (yep, that's ugly)
    const tags = params.tags.slice(1, -1).split(',').filter(item => item !== ', ').map((item, index, arr) => index % 2 !== 0 && ({ [arr[index - 1].trim().slice(2, -1)]: parseInt(item.trim(), 10) }))
      .filter(Boolean);
    let tagName = null;
    tags.forEach(tag => {
      // Useless eslint rule because only one entry in the object
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (tagName in tag) {
        const tagOption = new Option(tagName, tag[tagName], true, true);
        // add option to tags list
        $('#id_ad_tags').append(tagOption).trigger('change');
      }
    });

    btnSelector.click();
  }
};

export default {
  instantiateAdSearchForm,
};

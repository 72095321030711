import L from 'leaflet';
import 'leaflet-control-geocoder';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';

import { ATTRIBUTIONLEGEND, TILELAYERURL } from '../config';
import MessageView from '../views/MessageView';

const LAYER_MARKER_ID_COMPANY_ADDRESS = 'companyAddressID';

export const instantiateMap = (lat, lng) => {
  const coords = (lat && lng) ? [lat, lng] : [46.286, 2.455];
  const zoom = (lat && lng) ? 12 : 4; // we zoom-in on the company if we are updating it.

  const map = new L.Map('form-map', { center: coords, zoom });
  let marker = L.marker(coords);

  L.Control.geocoder({
    defaultMarkGeocode: false,
    collapsed: false,
    placeholder: gettext('Search address to geocode company...'),
    suggestMinLength: 3,
  })
    .on('markgeocode', event => {
      console.log(event.geocode.center);
      marker.setLatLng(event.geocode.center);
      map.panTo(event.geocode.center);
      document.getElementById('id_latitude').value = event.geocode.center.lat;
      document.getElementById('id_longitude').value = event.geocode.center.lng;
    })
    .addTo(map);

  L.tileLayer(TILELAYERURL, { attribution: ATTRIBUTIONLEGEND }).addTo(map);
  marker.addTo(map);
  marker.companyAddressID = LAYER_MARKER_ID_COMPANY_ADDRESS;

  map.on('click', event => {
    map.eachLayer(layer => {
      if (layer.companyAddressID === LAYER_MARKER_ID_COMPANY_ADDRESS) {
        map.removeLayer(layer);
      }
    });
    marker = L.marker(event.latlng).addTo(map);
    marker.companyAddressID = LAYER_MARKER_ID_COMPANY_ADDRESS;
    document.getElementById('id_latitude').value = marker.getLatLng().lat;
    document.getElementById('id_longitude').value = marker.getLatLng().lng;
  });

  return { map };
};

export const submitCompany = async event => {
  event.preventDefault();

  // const loader = new LoaderView();
  // document
  //   .getElementById('loader-progress')
  //   .appendChild(loader.render().el);

  const form = document.querySelector('#form-geocompany');

  form
    .querySelectorAll('.validation-error-message')
    .forEach(element => element.parentElement.removeChild(element));

  form
    .querySelectorAll('.has-error')
    .forEach(element => element.classList.remove('has-error'));

  try {
    const response = await fetch(form.action, {
      method: 'POST',
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      body: new FormData(form),
    });

    if (!response.ok) {
      // loader.remove();
      const errors = await response.json();

      // displaying error message on inputs
      Object.entries(errors).forEach(error => {
        const [name, value] = error;
        const input = document.getElementById(`id_${name}`) || null;
        if (input) {
          input.parentElement.classList.add('has-error');
          const messageView = new MessageView(value[0], 'validation-error-message block').render();
          input.parentElement.appendChild(messageView.el);
        }
      });
    } else {
      $.notify(
        { message: gettext('Company successfully updated.') },
        { type: 'success', placement: { align: 'center', from: 'top' } },
      );
      $('#myModal').modal('hide');
      window.location.reload();
    }
  } catch (error) {
    $.notify(
      { message: error },
      { type: 'danger', placement: { align: 'center', from: 'top' } },
    );
  }
};

export default {
  instantiateMap,
};

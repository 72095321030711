import MessageView from '../views/MessageView';

export const displayUnit = text => {
  const unitEl = document.getElementById('id_quantity').nextElementSibling.firstElementChild;
  // does not seems to be the best way to get the unit, to rework later.
  unitEl.innerHTML = text;
};

export const displayExpirationDate = isoDate => {
  const expirationEl = document.getElementById('id_expiration_date');
  expirationEl.value = new Date(isoDate).toLocaleDateString('fr');
};

export const submitFlux = async event => {
  event.preventDefault();

  const form = document.querySelector('.flux-form');
  form
    .querySelectorAll('.validation-error-message')
    .forEach(element => element.parentElement.removeChild(element));
  form
    .querySelectorAll('.has-error')
    .forEach(element => element.classList.remove('has-error'));

  try {
    const response = await fetch(form.action, {
      method: 'POST',
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      body: new FormData(form),
    });

    if (!response.ok) {
      const errors = await response.json();
      Object.entries(errors).forEach(error => {
        const [name, value] = error;
        const input = document.getElementById(`id_${name}`) || document.getElementById(`div_id_${name}`) || null;
        if (input.type !== 'hidden') {
          input.parentElement.classList.add('has-error');
          const messageView = new MessageView(value[0], 'validation-error-message help-block').render();
          input.parentElement.appendChild(messageView.el);
        }
      });
    } else {
      const isCreation = form.querySelector('#div_id_type') || form.querySelector('#div_id_type_ad');
      const successMessage = isCreation ? gettext('Ad successfully created') : gettext('Ad successfully updated');
      $.notify(
        { message: successMessage },
        { type: 'success', placement: { align: 'center', from: 'top' } },
      );
      $('#myModal').modal('hide');
      if (window.location.hash.includes('#frontend/')) {
        window.location.reload();
      } else {
        window.location.search = 'tab=tab-ads';
      }
    }
  } catch (error) {
    $.notify(
      { message: error },
      { type: 'danger', placement: { align: 'center', from: 'top' } },
    );
  }
};

export const selectAllPeriodicity = event => {
  event.preventDefault();
  event.stopPropagation();
  const link = event.target;
  const checkBoxes = $(link.previousElementSibling).find('input[type=checkbox]');
  checkBoxes.prop('checked', !checkBoxes.prop('checked'));
};

export default { submitFlux, displayUnit, selectAllPeriodicity };

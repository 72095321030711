import Backbone from 'backbone';

export default Backbone.View.extend({
  className: 'message-block',
  tagName: 'p',
  initialize (MessageText, MessageClass) {
    this.text = MessageText;
    this.className = MessageClass;
  },
  render () {
    this.el.className = this.className;
    this.el.innerHTML = this.text;
    return this;
  },
});

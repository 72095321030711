import { openLoadingModal } from '../../services';

export const CompanyInfoTabLoaded = () => {
  // define click event for ad update buttons
  const buttonsEdit = document.getElementById('btn-edit-company');

  buttonsEdit.addEventListener('click', event => {
    event.preventDefault();
    openLoadingModal(gettext('Edit company'));
    $('#modal-body').load(buttonsEdit.href, () => {
      // Refresh autocomplete form display (see issue #450)
      window.dispatchEvent(new Event('load'));
    });
  });
};

export default {
  CompanyInfoTabLoaded,
};

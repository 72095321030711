import L from 'leaflet';
import { openLoadingModal } from '../../services';
import 'leaflet.markercluster';

export const CompanyCustomerProfile = displayHeatMap => {
  // init bootstrap confirmation for flux
  $('[data-toggle=confirmation]').confirmation({
    rootSelector: '[data-toggle=confirmation]',
    // other options
  });

  const buttonSearchAd = document.querySelectorAll('#btn-customer-ad-search');

  buttonSearchAd.forEach(element => {
    element.addEventListener('click', event => {
      event.preventDefault();
      openLoadingModal(gettext('Search ads'));
      // load form content in modal
      $('#modal-body').load(element.href);
    });
  });

  document.addEventListener('DOMContentLoaded', () => {
    $('a[href="#company"]').on('shown.bs.tab', () => {
      window.app.map.invalidateSize();
    });
  });
  if (displayHeatMap) {
    const cluster = new L.MarkerClusterGroup(
      { maxClusterRadius: 25,
        singleMarkerMode: true,
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: false },
    );

    $.get('/api/business/v1/heat-map/?page_size=20000', data => {
      cluster.addTo(window.app.map);
      $.each(data.features, (index, value) => {
        const marker = L.marker(
          [value.geometry.coordinates[1], value.geometry.coordinates[0]],
        );
        marker.infos = value.properties;
        marker.addTo(cluster);
      });
    });

    const onMarkerClusterClick = a => {
      // display search tab
      const divAdHeatMap = $('#div-heat-map');
      const resultAdHeatMap = $('#results-div-heat-map');
      $('#tab-search').trigger('click');
      let markers;

      if (a.layer.getAllChildMarkers !== undefined) {
        markers = a.layer.getAllChildMarkers();
      } else {
        markers = [a.layer];
      }

      // empty div and generate data
      resultAdHeatMap.empty();
      const template = _.template($('#customer-ad-heat-map-template').html());

      markers.forEach((e, index) => {
        const properties = e.infos;
        // add custo css class
        if (index % 2 === 0) {
          properties.tr_class = 'odd';
        } else {
          properties.tr_class = 'even';
        }

        resultAdHeatMap.append(template(properties));
      });
      // show div and hide search result div
      divAdHeatMap.removeClass('d-none');
      $('#div-results').addClass('d-none');
    };

    // subscribe to both events because not the same if there is one or more markers (1: click, 1+: clusterclick)
    cluster.on('clusterclick', onMarkerClusterClick);
    cluster.on('click', onMarkerClusterClick);

    $(document).on('click', '#table-heat-map tr', event => {
      $.ajax({
        url: `/api/business/v1/ad/${event.target.id}/`,
        type: 'GET',
        dataType: 'json',
        beforeSend: () => {
          $('#ad-modal-body').html(`<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> ${gettext('Searching ...')}`);
        },
        success: data => {
          $('#ad-modal-body').html(data.html);
          $('#ad-modal').modal('show');
        },
      });
    });
  }
};

export default {
  CompanyCustomerProfile,
};

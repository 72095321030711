export const APIROOT = '/api/frontend/';
export const TILELAYERURL = '//{s}.tile.osm.org/{z}/{x}/{y}.png';
export const ATTRIBUTIONLEGEND = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
export const companyDetailUrl = id => `/frontend/geocompany/${id}`;
export const deleteSynergyUrl = id => `frontend/synergy/${id}/delete/`;

export default {
  APIROOT,
  TILELAYERURL,
  ATTRIBUTIONLEGEND,
  deleteSynergyUrl,
  companyDetailUrl,
};

import L from 'leaflet';
import 'leaflet.markercluster';
import { ATTRIBUTIONLEGEND, TILELAYERURL } from '../../config';

export const InitHeatMap = () => {
  const map = L.map('map', {
    minZoom: 4,
    maxZoom: 11,
  }).setView([47, 2.5], 6);

  L.tileLayer(TILELAYERURL, { attribution: ATTRIBUTIONLEGEND })
    .addTo(map);

  const cluster = new L.MarkerClusterGroup(
    { maxClusterRadius: 25,
      singleMarkerMode: true,
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: false },
  ).addTo(map);

  $.get('/api/business/v1/heat-map/?page_size=20000', data => {
    $.each(data.features, (index, value) => {
      const marker = L.marker(
        [value.geometry.coordinates[1], value.geometry.coordinates[0]],
      );
      marker.infos = value.properties;
      marker.addTo(cluster);
    });
  });

  const onMarkerClusterClick = a => {
    let markers;

    if (a.layer.getAllChildMarkers !== undefined) {
      markers = a.layer.getAllChildMarkers();
    } else {
      markers = [a.layer];
    }

    $('#modal-infos-body').empty();
    const template = _.template($('#ad-heat-map-template').html());

    markers.forEach(e => {
      $('#modal-infos-body').append(template(e.infos));
    });
    $('#modal-infos').css('z-index', '');
    $('#modal-infos').modal('show');
  };

  cluster.on('clusterclick', onMarkerClusterClick);
  cluster.on('click', onMarkerClusterClick);
};

export default {
  InitHeatMap,
};

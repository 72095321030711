import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

export const getApiRoutes = async apiRoot => {
  try {
    const response = await fetch(`${Backbone.history.location.origin}${apiRoot}`);
    if (!response.ok) throw new Error(response.statusText);
    return await response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export const openLoadingModal = title => {
  /*
  Open modal with loading spinner.
   */
  $('#modal-title').html(title);
  $('#modal-body').html('<div class="col12 text-center"><div style="width: 6rem; height: 6rem;" class="spinner-border text-dark" role="status"><span class="sr-only"></span></div></div>');
  $('#myModal').modal('show');
};

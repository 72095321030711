import 'nodelist-foreach-polyfill';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import jQuery from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';

import 'bootstrap-notify';
import 'backbone-relational';
import 'backbone.paginator';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'; // Re-uses images from ~leaflet package
import L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet-easybutton';
import 'leaflet-easybutton/src/easy-button.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import Loader from './views/LoaderView';
import { instantiateMap, submitCompany } from './forms/companyFormsHelpers';
import {
  displayUnit,
  displayExpirationDate,
  selectAllPeriodicity,
  submitFlux,
} from './forms/fluxFormsHelpers';
import {
  cancelUpdate,
  deleteSynergy,
  displayLessActions,
  displayMoreActions,
  showMap,
  submitSynergy,
  synergyTracingAllowance,
} from './forms/synergyFormsHelper';
import { getApiRoutes, openLoadingModal } from './services';
import { APIROOT, ATTRIBUTIONLEGEND, TILELAYERURL } from './config';
import { instantiateCustomerSearchForm } from './forms/customerAdSearch';
import { CompanyFluxTabLoaded } from './views/company/companyAdTab';
import { CompanyInfoTabLoaded } from './views/company/companyInfoTab';
import { CompanyUserTabLoaded } from './views/company/CompanyUserTab';
import { CompanyCustomerProfile } from './views/company/companyCustomerProfile';
import { instantiateAdSearchForm } from './forms/adSearch';
import { InitHeatMap } from './views/public/HeatMap';
import { it, fr } from './locales/datatable';
import { instantiatePublicSearchForm } from './forms/publicAdSearch';

// make jquery available in underscore; bootstrap and backbone
global.jQuery = jQuery;
global.$ = global.jQuery;
global._ = _;
global.L = L;
require('popper.js');
require('bootstrap');
require('bootstrap-autocomplete');
require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min');
require('bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.min.css');
require('@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css');

require('datatables.net-bs4')(window, $);
require('datatables.net-buttons-bs4')(window, $);
require('datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css');
require('bootstrap-confirmation2');
require('admin-lte');
require('admin-lte/dist/css/adminlte.min.css');
require('../scss/styles.scss');

global.Backbone = Backbone;

// Make underscore template more mustache-y
global._.templateSettings = {
  interpolate: /{{(.+?)}}/g,
  evaluate: /{%(.+?)%}/g,
};

const pageLanguage = document.documentElement.lang;
let language;

if (pageLanguage === 'it') {
  language = it;
} else {
  language = fr;
}

if (window.SENTRY_DSN != null) {
  Sentry.init({
    dsn: window.SENTRY_DSN,
    environment: window.SENTRY_ENVIRONMENT,
    // To set your release version
    // release: `my-project-name@${process.env.SENTRY_DSN}`,
    integrations: [new Integrations.BrowserTracing()],
    sendDefaultPii: window.SENTRY_SEND_PII,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0 ? window.SENTRY_ENVIRONMENT === 'dev' : 0.2,
    release: window.SENTRY_RELEASE,
  });
}

const loadContent = () => {
  const path = window.location.hash.substring(1);
  // home page and pages routed by backbone
  if ((['', 'recommendations', 'search', 'synergies/create/'].indexOf(path) >= 0) || (/^search?/.test(path))) {
    return;
  }

  // loader is created here to not conflict with profil page
  const loaderElement =  document
    .getElementById('loader-progress');
  if (loaderElement !== undefined && loaderElement !== null) {
    const loader = new Loader();
    loaderElement.appendChild(loader.render().el);
    const $boardlist = $('.board-list');
    $boardlist.empty();
    $boardlist.load(path, (response, status, xhr) => {
      if (xhr.status === 401) {
        window.location.href = '/login/?expired=true';
        loader.remove();
      } else {
        $('#loader').css('opacity', '0');
        if (xhr.status >= 500) {
          $.notify(
            { message: gettext('an error occurred. Admin have been informed') },
            { type: 'danger', placement: { align: 'center', from: 'top' } },
          );
        }
        loader.remove();
      }
      // Refresh autocomplete form display (see issue #450)
      window.dispatchEvent(new Event('load'));
    });
  }
};

$(document).ready(() => {
  window.onhashchange = loadContent;
  loadContent();

  $(document).on('click', '.route', event => {
    const url = $(event.target).attr('href');
    window.location.href = url;
  });

  $(document).ajaxError((event, request) => {
    if (request.status === 401) {
      window.location = '/login/?expired=true';
    }
  });

  // aut select tab if in URL
  const params = new URLSearchParams(document.location.search.substring(1));
  const tab = params.get('tab');
  if (tab !== undefined) {
    $(`#${tab}`).click();
  }
});

window.app = (function app () {
  // TODO: make a setting api call
  return {
    models: {},
    collections: {},
    views: {},
    router: null,
    apiRoot: '/api/frontend/',
    icons: [],
  };
}(jQuery));

// root urls used in the entire app (by router, model)
window.app.initApp = (loadRouter = true) => {
  (async () => {
    global.apiUrls = await getApiRoutes(APIROOT);
    const { default: { models, collections } } = await import('./models/index');

    // apiUrls needs to be resolve for models and collections to be loaded
    window.app.models = models;
    window.app.collections = collections;
    // new instance & model init. Maybe rewrite this later
    Backbone.Relational.store.addModelScope(window.app.models);
    window.app.companies = new app.collections.GeoCompanyCollection();
    window.app.geoentities = new app.collections.GeoEntityCollection();
    window.app.industries = new app.collections.IndustryCollection();
    window.app.fluxes = new app.collections.FluxCollection();
    window.app.synergies = new app.collections.SynergyCollection();
    window.app.subclassifications = new app.collections.SubclassificationsCollection();
    if (loadRouter) {
      const { default: Router } = await import('./router');
      window.app.router = new Router();
    }
  })();
};

window.app.initCustomerApp = () => {
  (async () => {
    global.apiUrls = await getApiRoutes(APIROOT);
    const { default: { models, collections } } = await import('./models/index');

    // apiUrls needs to be resolve for models and collections to be loaded
    window.app.models = models;
    window.app.collections = collections;
    // new instance & model init. Maybe rewrite this later
    Backbone.Relational.store.addModelScope(window.app.models);
    window.app.industries = new app.collections.IndustryCollection();
    window.app.synergies = new app.collections.SynergyCollection();
    window.app.subclassifications = new app.collections.SubclassificationsCollection();
  })();
};

/**
 * Global function used to instantiate FORMs map
 * and bind event handler to FORMS
 */
window.app.initCompanyForm = () => {
  // instanciate map
  const lat = document.getElementById('id_latitude').value;
  const lng = document.getElementById('id_longitude').value;
  (lat && lng) ? instantiateMap(lat, lng) : instantiateMap();
  document.querySelector('#form-geocompany').addEventListener('submit', submitCompany);
};

window.app.initFluxForm = () => {
  // each classification type has his own unit and expiration date.
  // It define which unit and expiration date is displayed
  const $classificationInput = $('#id_classification');

  // every classification change must update the unit displayed
  $classificationInput.on('select2:select', event => {
    // select2 seems to work only with jQuery ... because it's a jquery plugin !
    const selectedData = event.params.data;
    // set unit in div
    displayUnit(selectedData.unit);
    // set default expiration date
    displayExpirationDate(selectedData.expiration_date);
    // empty tags after classification change
    $('#id_tags').val(null).trigger('change');
    // clean search field
    $('#id_search_add').val('');
  });

  document
    .querySelector('.flux-form')
    .addEventListener('submit', submitFlux);

  document
    .querySelector('.select-all-periodicity')
    .addEventListener('click', selectAllPeriodicity);

  // init search autocomplete field
  $('#id_search_add').autoComplete({
    resolverSettings: {
      url: window.reverse('ad-search-autocomplete'),
    },
    noResultsText: '', // disable 'no result' entry
  }).on('autocomplete.select', (event, item) => {
    // clear preselection if already selected
    $('#id_classification').val(null).trigger('change');
    $('#id_tags').val(null).trigger('change');

    // if we select an autocomplete proposal, preselect matching classification
    const optionClassification = new Option(item.classification, item.id, true, true);
    // add classification option
    $('#id_classification').append(optionClassification).trigger('change');
    displayExpirationDate(item.expiration_date);
    displayUnit(item.unit);

    if (item.id_tag !== null) {
      const optionTag = new Option(item.text, item.id_tag, true, true);
      // add option
      $('#id_tags').append(optionTag).trigger('change');
    }

    if ($('#id_search_add').val() === ' ') {
      // prevent case where value is not empty
      $('#id_search_add').val('');
    }
  });
};

window.app.initCompanySynergy = () => {
  const mapButtons = Array.from(document.querySelectorAll('.showmap'));
  mapButtons.forEach(button => button.addEventListener('click', showMap));
};

window.app.initAddSynergyForm = () => {
  if (['', 'recommendations'].indexOf(window.location.hash.substring(1)) === -1) {
    const synergyStatus = document.querySelector('#id_status');
    synergyTracingAllowance({ target: synergyStatus });
    synergyStatus.addEventListener('change', synergyTracingAllowance);

    document.querySelector('button#actionsDisplay').addEventListener('click', event => {
      const targetClassList = Array.from(event.currentTarget.classList);
      if (targetClassList.indexOf('display-more') > -1) {
        displayMoreActions(event);
      } else {
        displayLessActions(event);
      }
    });
    document.querySelector('form').addEventListener('submit', submitSynergy);
    // Refresh autocomplete form display (see issue #450)
    window.dispatchEvent(new Event('load'));
  }
};

window.app.initUpdateSynergyForm = () => {
  const synergyStatus = document.querySelector('#id_status');
  synergyTracingAllowance({ target: synergyStatus });
  synergyStatus.addEventListener('change', synergyTracingAllowance);

  // cancel and submit
  document.querySelector('form').addEventListener('submit', submitSynergy);
  document.querySelector('.cancel').addEventListener('click', cancelUpdate);

  document.querySelector('button#actionsDisplay').addEventListener('click', event => {
    const targetClassList = Array.from(event.currentTarget.classList);
    if (targetClassList.indexOf('display-more') > -1) {
      displayMoreActions(event);
    } else {
      displayLessActions(event);
    }
  });
  // Refresh autocomplete form display (see issue #450)
  window.dispatchEvent(new Event('load'));
};

window.app.initDeleteSynergyForm = () => {
  const deleteButtons = Array.from(document.querySelectorAll('.delete-synergy'));
  deleteButtons.forEach(button => button.addEventListener('click', deleteSynergy));
};

window.app.initCompanyMap = (lng, lat) => {
  const focusMap = () => window.app.map.setView([lat, lng], 21);
  focusMap();
  document.querySelector('button.company-focus').addEventListener('click', focusMap);
};

window.app.initCompanyTab = () => {
  $(() => {
    $('#main-company a[data-toggle="tab"]').on('shown.bs.tab', e => {
      const target = $(e.target).attr('href');
      if (target !== '#company-synergies') {
        // clear synergy layer
        window.app.synergy_layer.clearLayers();
      }
    });

    // init bootstrap confirmation for flux refresh
    $('[data-toggle=confirmation]').confirmation({
      rootSelector: '[data-toggle=confirmation]',
    // other options
    });
  });
};

window.app.boardListCompanyTab = () => {
  $(document).ready(() => {
    $('#main a[data-toggle="tab"]').on('shown.bs.tab', e => {
      const target = $(e.target).attr('href');
      if (target === '#companies') {
        // clear synergy layer
        if (window.app.synergy_layer !== null && window.app.synergy_layer !== undefined) {
          window.app.synergy_layer.clearLayers();
        }
      }
    });

    const buttonSearchAd = document.querySelectorAll('#btn-ad-search');
    buttonSearchAd.forEach(element => {
      element.addEventListener('click', event => {
        event.preventDefault();
        openLoadingModal(gettext('Search ads'));
        // load form content in modal
        $('#modal-body').load(element.href, () => {
          // Refresh autocomplete form display (see issue #450)
          window.dispatchEvent(new Event('load'));
        });
      });
    });

    // click to open Search modal if there is search anchor
    const urlContainsSearch = document.location.hash.includes('#search?');
    if (urlContainsSearch) {
      buttonSearchAd[0].click();
    }
  });
};

window.app.initProfile = () => {
  // use in user's profile view
  $(() => {
    // init datatables
    $('#table-documents').dataTable({ language });
    $('#table-expired').dataTable(
      {
        language,
        searching: false,
        lengthChange: false,
        serverSide: true,
        processing: true,
        ajax: {
          url: '/api/frontend/ad/expired/',
        },
        columns: [
          { data: 'id', searchable: false },
          { data: 'classification',
            searchable: false,
            sortable: false,
            render (data) {
              return data.text;
            } },
          { data: 'company',
            searchable: false,
            sortable: false,
            render (data) {
              return `<a href="/#frontend/geocompany/${data.id}">${data.name}</a>`;
            } },
          { data: 'expiration_date',
            searchable: false },
        ],
      },
    );
    $('#table-expire-soon').dataTable({ language });
    $('#table-demands').dataTable({ language });
    $('#table-notifications').dataTable({ language });
    $('#table-validation').dataTable(
      {
        language,
        searching: false,
        lengthChange: false,
        serverSide: true,
        processing: true,
        ajax: {
          url: '/api/frontend/ad/validate/',
        },
        columns: [
          { data: 'id', visible: false },
          { data: 'classification',
            searchable: false,
            sortable: false,
            render (data) {
              return data.text;
            } },
          { data: 'company',
            searchable: false,
            sortable: false,
            render (data) {
              return `<a href="/#frontend/geocompany/${data.id}">${data.name}</a>`;
            } },
          { data: 'picture',
            searchable: false,
            sortable: false,
            render (data) {
              if (data !== null) {
                return `<img src="${data}" class="img img-thumbnail" style="max-height: 80px;" />`;
              }
              return gettext('no picture');
            } },
        ],
      },
    );

    $('[data-toggle=confirmation]').confirmation({
      rootSelector: '[data-toggle=confirmation]',
      onConfirm: () => {
        if (Array.from(document.querySelectorAll('[name=flux]:checked')).length > 0) {
          $('#form-selected-fluxes').submit();
        } else {
          $.notify(
            { message: gettext('You need to select flux.') },
            { type: 'danger', placement: { align: 'right', from: 'top' } },
          );
        }
      },
    });
    // aut select tab if in URL
    const params = new URLSearchParams(document.location.search.substring(1));
    const tab = params.get('tab');
    if (tab !== undefined) {
      $(`#${tab}`).trigger('click');
    }
  });
};

window.app.instantiateCustomerMap = (coordinates, zoomLevel) => {
  const map = L.map('map').setView([coordinates[1], coordinates[0]], zoomLevel);

  L.tileLayer(TILELAYERURL, { attribution: ATTRIBUTIONLEGEND }).addTo(map);
  // add synergy layer and control
  app.synergy_layer = L.geoJSON().addTo(map);
  return map;
};

window.app.geocoding = async (address, city) => {
  try {
    const response = await fetch(`https://nominatim.openstreetmap.org/?format=json&q=${address} ${city}`);
    const data = await response.json();
    if (data[0] === undefined && address !== '') {
      // If there was an address provided but no result we make a request to fetch the coordinates of the city
      return window.app.geocoding('', city);
    }
    return [data[0].lat, data[0].lon];
  } catch (error) {
    $.nofity(
      { message: gettext("Something wen't wrong with the geocoding services") },
      { type: 'danger', placement: { from: 'top', align: 'center' } },
    );
    return error;
  }
};

window.app.initZoomToFeature = (feature, zoom, useMarker) => {
  const { coordinates } = feature.geometry;
  const map = window.app.instantiateCustomerMap(coordinates, zoom);
  window.app.map = map;
  if (useMarker) {
    L.marker([coordinates[1], coordinates[0]]).addTo(map);
  }
};

window.app.addGeocompanyFeatures = geocompanies => {
  geocompanies.forEach(synergyCompany => {
    const companyCoords = synergyCompany.geometry.coordinates;
    const marker = L.marker([companyCoords[1], companyCoords[0]],
      {
        icon:  L.icon({
          iconUrl: synergyCompany.properties.industry.marker,
          shadowUrl: '',
          iconSize: [52, 52],
          shadowSize: [52, 40],
          iconAnchor: [26, 52],
          shadowAnchor: [20, 40],
          popupAnchor: [1, -51],
        }),
      }).addTo(window.app.map);
    const popupContent = `<strong>${synergyCompany.properties.name}</strong>`;
    marker.bindPopup(popupContent, { autoClose: false,
      closeOnClick: false });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  const companyJson = document.getElementById('company-json');
  const workshopJson = document.getElementById('workshop-json');
  if (companyJson) {
    const company = JSON.parse(companyJson.innerText);
    window.app.initZoomToFeature(company, 15, true);
    window.app.addGeocompanyFeatures(company.properties.synergy_companies.features);
  } else if (workshopJson) {
    const workshop = JSON.parse(workshopJson.innerText);
    window.app.initZoomToFeature(workshop, 10, false);
    window.app.addGeocompanyFeatures(workshop.properties.companies.features);
  }

  // for signup page
  const signUpForm = document.querySelector('#signup');
  if (signUpForm) {
    // map settings
    const defaultCoords = [4.079306, 48.293024];
    const map = window.app.instantiateCustomerMap(defaultCoords, 6);
    const marker = L.marker(defaultCoords).addTo(map);

    map.on('click', event => {
      const { lat, lng } = event.latlng;
      signUpForm.querySelector('#id_step4-lat').value = lat;
      signUpForm.querySelector('#id_step4-lng').value = lng;
      marker.setLatLng(event.latlng);
    });

    document.getElementById('localize').addEventListener('click', async event => {
      event.preventDefault();
      const address = document.getElementById('id_step4-address').value;
      const citySelect = document.getElementById('id_step4-postcode');
      if (citySelect?.selectedIndex) {
        const city = citySelect.options[citySelect.selectedIndex].innerText; // value is db id, we want text for NORMATIM
        const [lat, lng] = await window.app.geocoding(address, city);
        signUpForm.querySelector('#id_step4-lat').value = lat;
        signUpForm.querySelector('#id_step4-lng').value = lng;
        marker.setLatLng([lat, lng]);
      } else {
        $.notify(
          { message: gettext('You should set a city.') },
          { type: 'info', placement: { align: 'center', from: 'top' }, icon: 'fas fa-triangle-exclamation' },
        );
      }
    });
  }

  if (document.getElementById('signup-done')) {
    window.localStorage.clear();
  }
});

window.app.instantiateCustomerSearchForm = instantiateCustomerSearchForm;
window.app.instantiatePublicSearchForm = instantiatePublicSearchForm;
window.app.instantiateAdSearchForm = instantiateAdSearchForm;
window.app.CompanyInfoTabLoaded = CompanyInfoTabLoaded;
window.app.CompanyUserTabLoaded = CompanyUserTabLoaded;
window.app.CompanyFluxTabLoaded = CompanyFluxTabLoaded;
window.app.CompanyCustomerProfile = CompanyCustomerProfile;
window.app.InitHeatMap = InitHeatMap;

import { openLoadingModal } from '../../services';

export const CompanyFluxTabLoaded = () => {
  // Init tooltips
  $('[data-toggle="tooltip"]').tooltip();
  // define click event for ad update buttons
  const buttonsEditAd = document.querySelectorAll('.ad-edit');

  buttonsEditAd.forEach(element => {
    element.addEventListener('click', event => {
      event.preventDefault();
      openLoadingModal(gettext('Update an ad.'));
      // load form content in modal
      $('#modal-body').load(element.href);
    });
  });
  // define click event for add ad button
  const buttonAddAd = document.getElementById('btn-add-ad');
  buttonAddAd.addEventListener('click', event => {
    event.preventDefault();
    openLoadingModal(gettext('Place an ad'));
    // load form content in modal
    $('#modal-body').load(buttonAddAd.href);
  });
};

export default {
  CompanyFluxTabLoaded,
};

import Backbone from 'backbone';

export default Backbone.View.extend({
  templateName: '#loader-template',
  initialize () {
    this.template = _.template($(this.templateName).html());
    this.listenTo(app.companies, 'sync', this.remove, this);
    this.listenTo(app.fluxes, 'sync', this.remove, this);
    // this.listenTo(app.synergies, 'sync', this.remove, this);
  },
  render () {
    this.$el.html(this.template());
    return this;
  },
});

import MessageView from '../views/MessageView';
import { companyDetailUrl, deleteSynergyUrl } from '../config';

export const submitSynergy = async event => {
  event.preventDefault();
  event.stopPropagation();

  const form = event.target;
  const synergyTracingInputs = form.querySelectorAll('input');
  const synergyTracingSelects = form.querySelectorAll('select');

  Object.values(synergyTracingInputs).forEach(input => {
    input.disabled = false; // input is readonly
  });
  Object.values(synergyTracingSelects).forEach(select => {
    select.disabled = false; // select is readonly
  });

  const validationErrorMsgs = Array.from(form.querySelectorAll('.validation-error-message'));
  validationErrorMsgs.forEach(element => { element.parentElement.removeChild(element); });

  const hasErrors = Array.from(form.querySelectorAll('.has-error'));
  hasErrors.forEach(element => element.classList.remove('has-error'));

  try {
    const response = await fetch(form.action, {
      method: 'POST',
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      body: new FormData(form),
    });

    if (!response.ok) {
      const errors = await response.json();

      Object.entries(errors).forEach(error => {
        const [name, value] = error;
        if (name !== 'formset') {
          const input = document.getElementById(`id_${name}`) || null;
          if (input.type !== 'hidden') {
            input.parentElement.parentElement.classList.add('has-error');
          }
          const messageView = new MessageView(`${value[0]} <i>${name}</i>`, 'validation-error-message block').render();
          input.parentElement.parentElement.appendChild(messageView.el);
        } else {
          value.forEach((subForm, index) => {
            _.mapObject(subForm, (value2, key2) => {
              const input = form.querySelector(`#id_form-${index}-${key2}`);
              if (input.type !== 'hidden') {
                input.parentElement.parentElement.classList.add('has-error');
              }
              const messageView = new MessageView(`${value2} <i>(${key2})</i>`, 'validation-error-message help-block').render();
              input.parentElement.parentElement.appendChild(messageView.el);
            });
          });
        }
      });
    } else {
      const data = await response.json();
      const tmpSynergy = app.models.Synergy.findOrCreate({ id: data.pk });

      await tmpSynergy.fetch({
        success (synergy) {
          app.synergies.add(synergy, { merge: true });
          app.synergies.trigger('update');
        },
      });

      const path = window.location.hash;
      const id = path.split('/').filter(value => (typeof +value === 'number' && !Number.isNaN(+value)))[0];
      if (id !== '') {
        Backbone.history.navigate(companyDetailUrl(id));
      } else {
        window.location.href = '/';
      }

      $.notify(
        { message: gettext('synergy udpate successfull') },
        { type: 'success', placement: { align: 'center', from: 'top' } },
      );
    }
  } catch (error) {
    console.error(error);
    $.notify(
      { message: gettext('Unable to update the synergy') },
      { type: 'danger', placement: { from: 'top', align: 'center' } },
    );
  }
};

export const cancelUpdate = event => {
  const path = window.location.hash;
  const id = path.split('/').filter(value => (typeof +value === 'number' && !Number.isNaN(+value)))[0];
  if (id !== '') {
    event.preventDefault();
    event.stopPropagation();
    Backbone.history.navigate(companyDetailUrl(id));
  }
};

export const synergyTracingAllowance = () => {
  for (let i = 0; i < 5; i += 1) {
    $(`#id_form-${i}-term`).datepicker({ format: get_format('SHORT_DATE_FORMAT'),
      language: 'fr',
      autoclose: true });
  }
};

export const showMap = async event => {
  const buttonId = event.currentTarget.id;
  const synergyId = buttonId.split('-')[1];
  const model = app.models.Synergy.findOrCreate({ id: +synergyId }); // avoid error if this model has already been created
  await model.fetch();

  app.synergy_layer.clearLayers();
  app.synergy_layer.addData(model.attributes.lines);

  const IN_COLOR = '#16A107';
  const OUT_COLOR = '#9607A1';

  const synergyStyle = {
    color: OUT_COLOR,
    weight: 4,
    opacity: 0.75,
  };

  if (model.attributes.input.length > 0) {
    synergyStyle.color = IN_COLOR;
  }
  app.synergy_layer.setStyle(synergyStyle);

  model.attributes.input.forEach(flux => {
    const template = _.template($('#company-tooltip-template').html());
    const content = template({ company: flux.company, contact: '' });
    const [lng, lat] = flux.company.geometry.coordinates;
    const marker = L.marker([lat, lng]).addTo(app.synergy_layer);
    marker.bindPopup(content, { autoClose: false,
      closeOnClick: false }).openPopup();
  });

  model.attributes.output.forEach(flux => {
    const template = _.template($('#company-tooltip-template').html());
    const content = template({ company: flux.company, contact: '' });
    const [lng, lat] = flux.company.geometry.coordinates;
    const marker = L.marker([lat, lng]).addTo(app.synergy_layer);
    marker.bindPopup(content, { autoClose: false,
      closeOnClick: false }).openPopup();
  });

  if (app.synergy_layer.getBounds()) {
    window.app.map.fitBounds(app.synergy_layer.getBounds(), { padding: [30, 30] });
  }
};

export const deleteSynergy = async event => {
  event.preventDefault();

  const element = (event.currentTarget.tagName === 'BUTTON')
    ? event.currentTarget
    : event.currentTarget.parentElement;

  const formWrapper = document.getElementById(`synergy-delete-${element.id}`);
  const formUrl = `${Backbone.history.location.origin}/${deleteSynergyUrl(element.id)}`;

  try {
    const response = await fetch(formUrl);
    const form = await response.text();
    formWrapper.innerHTML = form;
  } catch (error) {
    console.error(error);
  }

  formWrapper.querySelector('.cancel').addEventListener('click', () => {
    formWrapper.innerHTML = '';
  });
  formWrapper.querySelector('form').addEventListener('submit', async evt => {
    evt.preventDefault();

    const form = evt.target;
    try {
      const response = await fetch(form.action, {
        method: 'POST',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        body: new FormData(form),
      });

      if (!response.ok) {
        const errors = await response.json();
        throw new Error(errors);
      }

      $.notify(
        { message: gettext('Synergy deletion successful') },
        { type: 'success', placement: { align: 'center', from: 'top' } },
      );
      if (window.location.hash.includes('#frontend/')) {
        window.location.reload();
      } else {
        window.location.search = 'tab=tab-synergies';
      }
    } catch (error) {
      console.error(error);
      $.notify(
        { message: gettext('An error occured: unable to delete the synergy') },
        { type: 'danger', placement: { align: 'center', from: 'top' } },
      );
    }
  });
};

export const displayMoreActions = event => {
  event.preventDefault();
  event.stopPropagation();

  const rows = Array.from(document.querySelectorAll('tr.d-none'));
  rows.forEach(tr => { tr.className = 'visible'; });

  event.target.classList.replace('display-more', 'display-less');
};

export const displayLessActions = event => {
  event.preventDefault();
  event.stopPropagation();

  const rows = Array.from(document.querySelectorAll('tr.visible'));
  rows.forEach((tr, index) => {
    if (index > 2) {
      tr.className = 'd-none';
    }
  });

  event.target.classList.replace('display-less', 'display-more');
};

export default {
  cancelUpdate,
  deleteSynergy,
  displayLessActions,
  displayMoreActions,
  showMap,
  synergyTracingAllowance,
};
